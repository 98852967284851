import React from 'react';

const CaseStudyAll = ({ className }: { className?: string }) => (
  <svg
    className={className}
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28.7849 34.6752H10.1152C10.0233 34.6714 9.93431 34.6434 9.85667 34.594C9.77904 34.5446 9.71557 34.4757 9.67317 34.3941C9.62908 34.3177 9.60596 34.231 9.60596 34.1429C9.60596 34.0547 9.62908 33.968 9.67317 33.8917L19.0178 20.1053C19.0641 20.0389 19.1262 19.9849 19.1979 19.9475C19.2696 19.9101 19.3491 19.8906 19.43 19.8906C19.5108 19.8906 19.5908 19.9101 19.6625 19.9475C19.7342 19.9849 19.7958 20.0389 19.8421 20.1053L29.1867 33.8917C29.2352 33.9665 29.2608 34.0537 29.2608 34.1429C29.2608 34.232 29.2352 34.3192 29.1867 34.3941C29.1494 34.4714 29.0927 34.5379 29.0224 34.5871C28.952 34.6364 28.8704 34.6666 28.7849 34.6752ZM11.0597 33.6704H27.8404L19.4604 21.271L11.0597 33.6704Z"
      fill="#1A1A1A"
    />
    <path
      d="M10.1759 34.6724C10.0839 34.6583 9.99768 34.6189 9.9267 34.5585C9.85573 34.4982 9.80285 34.4195 9.77412 34.3309L4.0062 16.5657C3.97658 16.4791 3.97259 16.3858 3.99394 16.2968C4.01529 16.2079 4.06122 16.127 4.1269 16.0633C4.189 16.0012 4.26584 15.9557 4.35014 15.9311C4.43444 15.9065 4.52345 15.9035 4.60919 15.9225L19.7218 19.9418C19.7978 19.963 19.8682 20.0012 19.9273 20.0534C19.9865 20.1056 20.0328 20.1705 20.0632 20.2433C20.1019 20.3142 20.1226 20.3936 20.1226 20.4744C20.1226 20.5551 20.1019 20.6346 20.0632 20.7054L10.7387 34.4916C10.6741 34.575 10.586 34.6369 10.4855 34.6692C10.3851 34.7015 10.277 34.7026 10.1759 34.6724ZM5.17195 17.1081L10.3369 33.0246L18.6968 20.6853L5.17195 17.1081Z"
      fill="#1A1A1A"
    />
    <path
      d="M19.521 20.9708C19.4158 20.9999 19.3048 20.9999 19.1996 20.9708C19.1409 20.922 19.0941 20.8604 19.0627 20.7907C19.0314 20.7211 19.0162 20.6453 19.0186 20.569V5.59697C19.0183 5.50659 19.0427 5.41793 19.0887 5.34012C19.1347 5.26231 19.2008 5.19829 19.2801 5.15491C19.358 5.10472 19.4484 5.07812 19.5411 5.07812C19.6337 5.07812 19.7247 5.10472 19.8026 5.15491L34.9151 16.1275C34.9931 16.1833 35.0532 16.2605 35.0888 16.3495C35.1244 16.4385 35.1338 16.5357 35.1158 16.6299C35.0979 16.7235 35.055 16.8105 34.9912 16.8813C34.9274 16.9522 34.8454 17.0043 34.7542 17.032L19.6417 21.0513L19.521 20.9708ZM20.0234 6.48134V19.8053L33.4683 16.2683L20.0234 6.48134Z"
      fill="#1A1A1A"
    />
    <path
      d="M28.7853 34.6774C28.7048 34.6787 28.6256 34.659 28.5551 34.6202C28.4847 34.5814 28.4254 34.525 28.3834 34.4563L19.0383 20.6702C19.009 20.5787 19.009 20.4803 19.0383 20.3888C19.0285 20.3289 19.0285 20.2679 19.0383 20.208C19.0688 20.1353 19.1155 20.0704 19.1747 20.0182C19.2339 19.966 19.3042 19.9277 19.3803 19.9065L34.4928 15.8873C34.5817 15.8684 34.6737 15.8713 34.7612 15.8958C34.8487 15.9203 34.9291 15.9658 34.9952 16.0281C35.0821 16.129 35.1314 16.2566 35.1356 16.3897C35.1443 16.4361 35.1443 16.484 35.1356 16.5305L29.3681 34.2957C29.3394 34.3843 29.2865 34.4632 29.2155 34.5236C29.1446 34.5839 29.0584 34.6233 28.9663 34.6374L28.7853 34.6774ZM20.2644 20.6099L28.7853 33.0296L33.9703 17.1131L20.2644 20.6099Z"
      fill="#1A1A1A"
    />
    <path
      d="M19.5203 20.9633H19.38L4.26746 16.944C4.17624 16.9164 4.09424 16.8643 4.03048 16.7934C3.96672 16.7226 3.92376 16.6358 3.90586 16.5422C3.88793 16.448 3.89724 16.3506 3.93285 16.2616C3.96846 16.1725 4.02858 16.0956 4.10653 16.0398L19.2191 5.06697C19.2992 5.02299 19.3893 5 19.4806 5C19.5719 5 19.6616 5.02299 19.7416 5.06697C19.8233 5.10936 19.8924 5.17259 19.9418 5.25022C19.9912 5.32786 20.0189 5.41734 20.0228 5.50928V20.481C20.0213 20.5588 20.0026 20.6352 19.9678 20.7048C19.933 20.7743 19.8834 20.835 19.8221 20.8829C19.7304 20.9356 19.6261 20.9634 19.5203 20.9633ZM5.55342 16.2608L19.0179 19.7978V6.47387L5.55342 16.2608Z"
      fill="#1A1A1A"
    />
  </svg>
);

export default CaseStudyAll;
